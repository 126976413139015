.minimal-header{
    position: absolute;
    top:0;
    height: 100vh;
    left: 0;
    z-index: 9998;
    min-width: 100%;
    pointer-events: none;

}

.header-page-id p{
    opacity: 0;
}
header > div{
  
}
.header-menu-link {
    display: flex;
    pointer-events: all;
}
.header-inner-content{
    height: 120px;
    width:94.6svw;
    margin:auto;
    margin-inline: 2.7svw !important;
    margin-bottom:2.7svw;
    display: flex;
    justify-content: right;
    align-items: end;

    position: absolute;
    bottom:0;
    left: 0;


}
.header-menu-expanded{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: calc(100% - 50px);
    position: absolute !important;
    top:0;
    left:0;
    margin-top:25px;
    margin-left: 25px;
    margin-bottom: 25px;
    overflow: hidden;

}
.header-toggled .header-menu-expanded{

    opacity: 1;
}


.header-menu-expanded > div{
    display: flex;
    flex-direction: column;
    opacity: 0;
 
    transition: 400ms ease-in opacity;
 
}
.header-toggled .header-menu-expanded > div{
    opacity: 1;
    transition: 400ms ease-in opacity 400ms;
}
.header-menu-expanded div:last-child > a{
    color:#0F4BBE;
    
}
.header-menu-expanded > .header-page-id{
    transition: 200ms ease all 700ms;
    opacity: 1;
    padding-bottom: 2em;
    overflow: hidden;
    max-height: 0em;
    position: absolute;
    
}
.header-toggled .header-page-id{
    transition: 200ms ease all 0ms !important;
    opacity: 0 !important;
}

.return-home-button{
    position: absolute !important;
    opacity: 1 !important;
    z-index: 10;
    top:-1.8em;
    left: 0;

    transition: 400ms ease all 700ms !important;

}
.return-home-button a{
    font-size: 1.8em;
    color: #0F4BBE !important;
    padding-left: 1em;
    text-decoration: none;
    
}
.return-home-button a:before{
    content: '->';
    position: absolute;
    left:0;
    
    transform: rotate(180deg) translateY(-2px);
}
.header-toggled .return-home-button{
    transition: 400ms ease all !important;
    opacity: 0 !important;
}
.header-page-id p{
    font-size: 1.8em;
    padding-bottom: 1.8em;
    line-height: 1em;
}
.header-menu-expanded div:last-child > a::after{
    content:'->';
    transform: translateX(-5px);
    padding-left: 10px;
    font-size: 2em;
    opacity: 0;
    transition: 400ms ease-in opacity;
    
}
.header-toggled .header-menu-expanded div:last-child > a::after{
    opacity: 1;
    transition: 400ms ease-in opacity 700ms, 300ms ease-out transform;
}
.header-menu-expanded div:last-child > a:hover::after{
    transform: translateX(0px);
}
.header-menu-expanded div > p, .header-menu-expanded div > a{
    cursor: pointer;
    display: flex;
    overflow: hidden;
    margin:0px;
    text-decoration: none;
}
.header-menu-expanded div > p >span, .header-menu-expanded div > a >span{
    font-size: 2em;
}

.header-menu-toggler{
    pointer-events: all;
    right:0;
    display: flex;
    align-items: end;
    justify-content: end;
    background-color: white;
    border-radius: 15px;
    width: 82px;
    height: 82px;
    transform-origin: bottom right;
    min-width: 0;
    transition: 700ms cubic-bezier(0.76, 0, 0.24, 1) all, max-width 150ms ease ;
    box-shadow: rgba(10, 10, 122, 0.057) 0px 7px 29px 0px;
}
.header-toggled .header-menu-toggler{
    min-width: calc(82px * 4);
}
.header-toggled .header-menu-toggler{
    width: 328px !important;
    height: 60vh;
    transform: scale(1) !important;
}
.header-hamburger{
    cursor: pointer;
    padding-inline:25px;
    max-height:80px;
    position: relative;
    z-index: 9999;
   
}
.header-menu-toggler svg{
    margin: 0;
}
.header-menu-toggler *{
    font-family: var(--font-family);
    font-size: var(  --font-size-base);
    font-weight: 500;
    position: relative;
    
}
.header-menu-toggler > p{
    text-align: right;

    cursor: pointer;
}
.header-menu-toggler > p, .header-menu-expanded > p, .header-menu-toggler > a, .header-menu-expanded > a{
    display: flex;
    overflow: hidden;
}
.header-inner-content .header-logo, .header-inner-content .header-hamburger{
    display: flex;
    align-items: center;
    height: 100%;
}
.header-menu{
    position: fixed;
    bottom:0;
    width:92%;
    height: 100vh;
    margin-inline:4%;
    margin-bottom:5em;
    
    pointer-events: none;
    display: flex;
}
.header-menu > div{
    width: calc(50% - 1%);
}
.header-menu-button{
    transform:translatex(10px)
}
@media only screen and (max-width:800px){
 
    .header-inner-content{
        width: 90svw;
        margin-inline: 5svw !important;
        margin-bottom:5svw;
        position: fixed !important;
        
    }
    .header-menu-toggler{
        max-width: 82px !important;

    }

}




.hamburger-button{
    cursor: pointer !important;

    transition: all 500ms ease;
    overflow: hidden;
    margin-inline:2.1vw;

}

.hamburger-button *{
    cursor: pointer;
    transition: all 500ms ease-out;
    transition-delay: 100ms;
    stroke: var(--primary-color) !important;

    transition-delay: 400ms;
}


.active.hamburger-button *{

    transition-delay: 100ms;
}

.hamburger-button:hover{

    transform-origin: right center;
}
.hamburger-button.active .line-1   {
    transform: translateY(calc(50% - 2px));
}
.hamburger-button.active .line-3{
    transform: translateY(calc(-50% + 2px));
}
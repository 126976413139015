.section-one{
    background-color: var(--secondary-color);
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.section-two{
    background-color: var(--primary-color);
    min-height: 100vh;
}
.section-three{
    background-color: var(--third-color);
    min-height: 100vh;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}



.page-five{
    padding-top: 2.5vw;
    position: relative;
    z-index: 100;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.page-five > div{
    width: 100%;
    max-width: 2100px;
}
.contact-wrap{
    width: 100%;
    height: 90%;
    display: flex;
 
    align-items: end;
    font-family: 'Inter', sans-serif;
    color: var(--third);
    text-transform: uppercase;
    font-size:8.8em;
    top:0;
    font-weight: 800;
    letter-spacing: -4px;

    position: relative;
    transition: all 200ms ease;
}
.get-in-touch{
    width: 100%;
    font-size: 14.8vw;
    letter-spacing: -1vw;
    cursor: pointer;
    position: relative;
}
.get-in-touch:active{
    transform: translateY(3px);
}
.opacity{
    opacity: 0;
}
.acknowledgement-of-country{
    max-width: 550px;
}
.acknowledgement-p{
    font-weight: 500;
    font-size: 2em;
    margin:0;
    color:var(--primary-color)
}
.footer-dialouge{
    display: flex;
    justify-content: space-between;
}
.footer-links{
    display: flex;
    flex-direction: column;
}

.footer-links > a{
    position: relative;
    font-weight: 500;
    font-size: 2em;
    cursor: pointer;
    padding-right: 1.1em;
    color:#0F4BBE;
}
.footer-links > a::after{
    content: ' ->';
    transition: 500ms ease 200ms;
    position: absolute;
    right: 0;

}

.footer-links > a:hover::before{
    left: auto;
    right: 0;
    width: 0;
   
}
.footer-links > a:hover::after{
    transform: translateX(5px);
   
}


 



.cursor-wrapper{
  transform: translateX(-83vw) translateY(-50vh);
  overflow: visible;

}
.odd-cursor {
  position: absolute;
  z-index: 9999 !important;
}
.quick-links{
  margin-top:5vh;
  width: 60%;
  margin-bottom: 10vh;
  margin-inline: 5%;
  display: flex;
  justify-content: space-between;
  gap: 2em;
  opacity:0.8;
  position: absolute;
  bottom: 0;
  z-index: 9999;
}
.insta-top{
  display: flex;
  justify-content: space-between;
  gap: 2em;
  flex-direction: row;
}

.resume{
  color: var(--background);
  font-size: large;

  position: relative;
}
.resume:hover{
  cursor: pointer;
}
.resume::after{
  content:'';
  width: 0;
  background-color: var(--background);
  right:0;
  height: 1px;
  position: absolute;
  bottom:0;
  transition: all 500ms ease ;
}
.resume:hover::after{
  width: 100%;
  left:0;
}

.page-one{
    background-color: var(--background);
   
}
.first-page-wrap{
    width: 100%;
    height: calc(100vh - 2.5vw);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.title{
    font-weight: 800;
}
.main-page-title {
    font-size: 12.5vw;
letter-spacing: -0.058em;
    font-weight: 800;
    color:var(--primary-color);
   text-align: center;
   width: 100vw;
    
}
.more-active-project-name span{
    white-space: nowrap !important;
}

body:has(.project-details) .header-toggled .header-menu-toggler{
    width: 328px !important;
    min-width: 328px !important;
    transform: scale(1) !important;
}
.main-page-intro{
    width: 556px;
    position: relative;
    z-index: 100;
}
.scrolled .main-page-intro{
    z-index: 1000 !important;
}

.footer-links a{
    text-decoration: none;
}
.main-page-p{
    font-weight: 500;
    font-size: 2em;
    color:var(--primary-color);
    width: 556px;
    position: relative;
    z-index: 1000;
}
.main-page-buttons-wrap{
    width: 80%;
    display: flex;
    justify-content: space-between;
}
.main-page-buttons-wrap > a > a{
    position: relative;
    font-weight: 500;
    font-size: 2em;
    cursor: pointer;
   display: block;
   height: 1.4em;

    color:var(--primary-color)
}
.website-link{
    text-decoration: none;
    width: fit-content;
    display: block;
    padding-left: 0 !important;
}
.website-link .text-reveal-container{
    margin:0;
}
.main-page-buttons-wrap > a > a span, .website-link > div span{
    position: relative;
    padding:0 !important;
    padding-right: calc(1.1em + 5px) !important;
    padding-bottom: 0.2em !important;
}
.main-page-buttons-wrap .text-reveal-container {
    margin:0 !important;
}
.main-page-buttons-wrap > a > a span::after, .website-link > div span::after{
    content: ' ->';
    transition: 500ms ease 200ms;
    position: absolute;
    right: 5px;

}
.main-page-buttons-wrap > a > a span::before, .website-link > div span::before{
    content: '';
    width:calc(100% - 5px);
    margin-right: 5px;
    height: 0.1em;
    background-color:var(--primary-color);
    position: absolute;
    bottom:0;
    left: 0;
    transition: 1000ms cubic-bezier(0.76, 0, 0.24, 1) all;

}
.main-page-buttons-wrap > a > a span:hover::before, .website-link > div span:hover::before{
    left: auto;
    right: 0;
    width: 0;
   
}

.get-in-touch.email-copied{
    font-size: 14.7vw;
    letter-spacing: -1vw;
}
.main-page-buttons-wrap > a > a span:hover::after, .website-link > div span:hover::after{
    transform: translateX(5px);
   
}
.main-page-button-secondary{
    color: #0F4BBE !important;
}
.main-page-button-secondary span:before{
    background-color: #0F4BBE !important;
}
.blue-link{
    color: #0F4BBE !important; 
}

.floating-heads-element{
    height: 15vw;
    max-height: 30vh;
    width: 100%;
    position: relative;
    margin-right: 2% !important;
    margin-top: -6%;
    min-width: 150vh;
    align-self: flex-end;
    
}
.floating-head{
    /* background-image: url(/public/AppImages/floatingHead2.webp); */
    object-fit: cover;
    user-select: none;
    height: 100%;
    aspect-ratio: 1;
    mix-blend-mode: darken;
    position: absolute;
    top: 0;
    right: 50%;
    background-size: contain;
    filter: blur(1px);
    transition: scale 500ms ease !important;

}
.floating-heads-element:has(.floating-head:hover) .floating-head{
    scale: 0.98 !important;
}

.floating-heads-element:has(.floating-head:hover) .floating-head:hover{
    scale: 1.02 !important;
}
.floating-head.head-2, .floating-head.head-3{


    opacity: 1;
    filter: blur(2px);
}

.floating-head.head-1, .floating-head.head-2, .floating-head.head-4{
    transform: scaleX(-0.9);
}
.floating-head.head-1{
    transform: scaleX(-0.9) scaleY(0.9);
}
.floating-head.head-2{
    transform: rotate(40deg) scale(0.5);
    filter: blur(2.5px);
    top:-40%;
    right:43%;
}
.floating-head.head-3{
    transform: rotate(-50deg) scale(0.7);
    top:-20%;
    right:7%;
}
.floating-head.head-4{
    filter: blur(3.5px);
    transform: rotate(-170deg) scale(0.4);
    top:-40%;
    right:56%;
}
.floating-head.head-5{
    filter: blur(2px);
    transform: rotate(-170deg) scale(1);
    top:100%;
    right:0%;
}
.floating-head.head-6{
    filter: blur(1px);
    transform: rotate(110deg) scale(1.2);
    top:0%;
    right:-7%;
}
.floating-head.head-7{
    filter: blur(3.5px);
    transform: rotate(-240deg) scale(0.35);
    top:35%;
    right:7%;
}
.floating-head.head-8{
    filter: blur(2px);
    transform: rotate(70deg) scale(0.6);
    top:160%;
    right:8%;
}


.num-column-1 *{
    text-align: right;
}
.site-title-container{
    position: fixed;
    top:0;
    left: 0;
    height:100vh;

    display: flex;
    justify-content: center;
    width: 94.6vw;
    padding-inline: 2.7vw;
    align-items: center;
}

.site-title-wrap{
    height:fit-content;
    display: flex;
    overflow: hidden;
    /* transform: translateX(-1em) !important; */
}
.site-title-wrap .site-title{
    margin-block:0; 
   
    white-space: nowrap !important;
    font-size: 12.5vw;
    transform: translateY(1em) scale(0.8);
    transform-origin: center center;
letter-spacing: -0.058em;
    margin-right: 0.05em;

    font-weight: 800;
    color: var(--primary-color);
}

.page-three{

    min-height: 150vh;            /* fall-back */
    min-height: calc(-moz-available * 1.5) ;
    min-height: calc(-webkit-fill-available* 1.5);
    min-height: calc(fill-available* 1.5);
    height:fit-content;
    margin-bottom: 200px;
    margin-top:-50vh;


}
.page-three > div{
    width:100%;

}
.coming-soon{
    cursor: not-allowed;
}
.projects-wrap{
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: end;

    padding-top:20vh;
}
.projects-list{
    mix-blend-mode: difference;
    transform-origin:top right;
    /* scale: 3; */
    width: 50.2vw;

  

 
    scale:3;
    transform: scale(0.32);
    
}
.project-more-details-desc{
    width: 46.8vw;
    font-weight: 500;
    font-size: 2em;
    color: var(--primary-color);
    opacity: 0;
    transition: 500ms ease all 1400ms;
}
body:has(.page-three.read-more) .project-more-details-desc{
    opacity: 0.99;
}
.projects-list > .text-reveal-container > .main-page-p{
    margin-bottom: 20vh;
    color: #f0b441;
}


.project-tile{
    position: relative;
    margin-top:-2px;
    margin-bottom:-2px;
    overflow: hidden !important;
    transition: max-height 1200ms linear;
}

.project-tile:hover{
    cursor: pointer;
}
.project-line-top, .project-line-bottom{
    position: absolute;
    width: 60%;
    height: 2px;

    background-color: var(--third-invert);
    transition: all 500ms ease-out;
}
.project-line-top{
    top:0;
}
.project-line-bottom{
    bottom:0;
}
.project-title{
    font-size: 4.87vw;
    font-weight: 800;
    color:var(--main-invert);
    letter-spacing: -0.065em;
    padding-top: 5px;
    white-space: nowrap !important;


}
.project-tile:hover .project-line-top,
.project-tile:hover .project-line-bottom
{
    width:100% !important;
}

.project-short-desc{
    position: relative;

    height:0px;
    overflow: visible;
    color:var(--main-invert);
    font-size: 0.6em;
    font-weight: 500;
    display: flex;
    justify-content: space-between;
    transition: all 0.5s ease-out;
}
.project-short-desc>div{
    width: 30%;
}
.short-desc-main{
    position:absolute;
    bottom:0;
    left:0;
    color:var(--main-invert);
    font-size: 0.6em;
    font-weight: 600;
    margin-left:1%;
    padding-bottom: 20px;
}
.project-short-desc{
    padding-top: 5px;
    padding-bottom: 0px;
    opacity: 1 !important;
}
.project-short-desc div{
    width: 31%;
    font-size: 1em;
}
.project-tile:first-child .project-line-top, .project-tile:last-child .project-line-bottom{
    display: none;

}
.short-desc-links-wrap{
    width: 33%;
}
.short-desc-links{

    width: max-content !important;
  line-height: 1.4em;
  font-size: 1.4em !important;
  cursor: pointer;
}
.short-desc-links a, .short-desc-links p{
    font-size: 1.4em !important;
    width: max-content;
}
.project-short-desc *{
    opacity: 1 !important;

}
.project-short-overview{
    padding-left:20px;
}
.project-line{
    /* width:0%; */
    opacity: 1 !important;
}
.project-tile *{
    opacity: 1;
}
.project-tile{
    /* transform: translateX(-60px); */
}
.title-letter-1, .title-letter-2, .title-letter-3, .title-letter-4, .title-letter-5{
    opacity: 1;
} 
.pointer-events{
    /* pointer-events: none; */
}
.overflow{
    overflow: hidden !important;
}
.threejs-background-wrap{
    z-index: 1 !important;
    position: fixed;
    width:46.6vw;
    height: 100%;
    left: 2.7vw;
    display: flex;
    justify-content: end;
    align-items: end;
    z-index: -2;
    pointer-events: none;
}
.threejs-background{
    background-color: white;
    width:100%;
    height: 0px;
    border-radius: 1em;
    transform: scale(0.713);
    clip-path: inset(0 0 0 0);
}
body:has(.more-active-project-page) .threejs-background-wrap{
    width:calc(94.5vw);
    height: calc(100vh - 2.7vw);
    margin-top: 2.7vw;
}

body:has(.more-active-project-page) .threejs-background{
    transition: transform 1500ms ease-in-out;
    transform: scale(4) !important;
    
}
.project-tile > div{
    
}
.projects-list{
    position: relative;
    z-index: 10;
}
.project-long-desc{
    position: relative;
    z-index: 100;
    padding-top: 0px;
    padding-bottom: 0px;
    display: flex;
    overflow: hidden;
    height: 1px;
    justify-content: space-between;
    transition: all 1500ms ease;
    letter-spacing: 0px;
    line-height: 1.05em;
}
.project-long-desc div{
    width:31%;
    /* transform: scaleY(0.8); */
    font-size: 1.02em;
    color: var(--main-invert);

    
}
.short-desc-overview{
  
}
.short-desc-overview > div{
    transition: all 1500ms ease;
    position: absolute;
    width: 100%;
}
.active{
    cursor: default;
}
.long-desc-col1{
    color:var(--main-invert);
    font-size: 1em;
    font-weight: 500;
}
.project-tile:hover .project-short-desc{
    padding-bottom: 40px !important;
}
.short-desc-links{
    position:relative;
    pointer-events: none;
}
.short-desc-links span a{
    position:absolute;
    text-align: right;
    right:0;
    color:var(--main-invert) !important;
    transform: translateX(-30px);
    font-weight: 500;
    font-size: 1em;
}
.active{
    cursor: default !important;
}
.active .project-short-desc{
    padding-bottom: 40px !important;
}
.active .project-long-desc{
    padding-top:10px;
    padding-bottom: 200px !important;
}
.active .short-desc-overview > div{
    margin-top: -200px !important;
}
.threejs-wrap{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-height:calc(100vw/2);
    pointer-events: none;
    width: 100%;
    opacity: 0.99;
    aspect-ratio: 16/9;
    transform-origin: center;
}
.threejs-wrap:has(.louis-canvas){
    width: 200%;
    max-height:calc(100vw);
    display: flex;
    align-items: center;
    aspect-ratio: 16/9;

}
.threejs-wrap:has(.project-canvas){
    z-index: 2;
}
.project-canvas{
width: 200% !important;
height: 100vw !important;
max-height:calc(100vw);
display: flex;
align-items: center;
aspect-ratio: 16/9;
transform: translateX(-25vw) !important;
}
.project-canvas canvas{
    
    
}



.louis-canvas, .louis-canvas > div{
    height: 100% !important;
    width: 100% !important;
}
.louis-canvas > div{
    display: flex;
    align-items: center;
    justify-content: center;
}
.louis-canvas canvas{
    height: 60% !important;
    width: 60% !important;
}

.threejs-wrap div{
    pointer-events: none !important;
}
.project-tile.read-more-tile .project-long-desc{
    padding-top:10px !important;
    padding-bottom: 0 !important;
}
.project-tile.read-more-tile .short-desc-overview > div{
    transition: all 1500ms ease !important;
    margin-top: 0 !important;
}
.short-desc-links{
    height: 1.4em;
    overflow: hidden;
}
.short-desc-links a{
    position: absolute;
    top:100%;
    text-decoration: none;
    font-size: 1em;
}
.short-desc-links p, .short-desc-links a{
    transition: 500ms ease all 1s;
    margin: 0;
}
.active .short-desc-links a{
    transition: 500ms ease all 350ms;
    top:0;
}
.active .short-desc-links p{
    transition: 500ms ease all 350ms;
    transform: translateY(-1em);
}
.link-view-more{
    color: #F0B441!important;
}
.project-tile.active .short-desc-links{
    pointer-events: all !important;
    
   
}
.page-four{
    z-index: 5;
    position: relative;
    background-color: transparent !important;
    height: min-content;
    overflow: hidden;
}
.cards-wrap{
    padding-top: 30vh;
}
.scrolled .header-menu-toggler{
    width: 211px !important;
    max-width:  calc(82px * 4);
    
}
/* .scrolled .header-page-id > p{
    transform: translate(0px, 0.1em);
} */
.page-two .main-page-p{
   width: 556px;
}
.page-two{
    margin-bottom: 60vh;
    height: fit-content;
    padding-bottom: 20vh;
}
.section-start{
    height:100px
}
.pin-spacer{
    pointer-events: none;
}
.page-four{
    width: 100%;
    padding-inline: 0;
}
.more-active-project-page{
    min-height: 200vh;
}
.project-canvas canvas{
    transform: scale(0.8);
    margin-top:100vh
}
body:has(.more-active-project-page) .louis-canvas{
    display: none;
}
body:has(.more-active-project-page) .project-canvas canvas{
    margin-top:0 !important;
}
body:has(.more-active-project-page) .project-canvas > div{
    transition: ease-out all 1000ms;
    transform: translate(0px) !important;
   
}
body:has(.more-active-project-page) .project-canvas > div{
    transition: 2000ms cubic-bezier(0.76, 0, 0.24, 1) all !important;
    transform: translateY(-90vw) !important;

}
body:has(.more-active-project-page) header{
    position: fixed !important;
}
body:has(.more-active-project-page) .header-menu-toggler{
    transition: none !important;
    transform: scale(0.8) !important;
}
body:has(.more-active-project-page) .page-five{
    display: none;
}
body:has(.more-active-project-page){
    overflow: hidden !important;
}
body:has(.more-active-project-page) .header-page-id, body:has(.more-active-project-page) .header-page-id span{
    opacity: 1 !important;
}
body:has(.portfolio-page-container) .return-home-button{
    opacity: 0 !important;
}

body:has(.portfolio-page-container) .project-home{
    opacity: 0 !important;
}
.more-active-project-name{
    height: 100vh;
    position: fixed;
    width: calc(100vw - 2.7vw*2);
    padding-inline: 2.7vw;
    display: flex;
    align-items: center;
    z-index: 20;

}
.more-active-project-name > .text-reveal-container> h1{
    font-size: 9vw;
    line-height: 9vw;
    letter-spacing: -0.05em;
    color: var(--primary-color);
    white-space:nowrap;
    margin-top:-5px;
}
.portfolio-page-container.amp .text-reveal-container> h1{
    font-size: 8.3vw;
    line-height: 8.3vw;
}
.large-video-section{
    aspect-ratio: 16/9;
    margin-bottom: 2.7vw;
}
.project-canvas-wrap{
    top:0%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translate(-50%, -0%);
}
.large-video-section video{
    width: 100% !important;
    height: 100% !important;
}
.project-tile-3 .project-title{
    font-size: 4.52vw;
}
.short-desc-links{
    padding-right: 20px;
}
.header-page-id p{
    transition: 100ms ease-out all 0ms, margin 1000ms cubic-bezier(0.76, 0, 0.24, 1);
}
.App:not(.scrolled) .header-page-id p{
    transition: 100ms ease-out all 0ms, margin 500ms cubic-bezier(0.76, 0, 0.24, 1);
    margin-top: 1.5em !important;
}
.loading-container{
    transition: all 1200ms cubic-bezier(0.76, 0, 0.24, 1);
}
.cursor > div, header > div > div{
    scale: 1 !important;
   
    transform-origin: bottom right;
}
.project-tile.active .short-desc-overview > div:not(.project-contribution){
    font-size:1.2em !important;
}
body:has(.loading-container) .cursor circle, body:has(.loading-container) header > div > div{
    scale: 0.01 !important;
}
.short-desc-overview > div{
    font-size: 1.02em !important;
}
.loading-numbers > div{
    font-weight: 600;
}


.loading-container.hide{
    clip-path: polygon(0 0, 100% 0, 100% 0, 0 0) !important;
}
.loading-container .main-page-title .text-reveal-element{
height: 1em;
}
.main-page-intro{
    z-index: 9997;
    opacity: 0;
}
.short-desc-overview > .project-contribution{
    position: absolute;
    font-size: 1.8em !important;
    bottom: calc(40px - 1.2em);
    overflow: hidden;
    display: flex;
}
.grid-box-desc .box-2{
    align-items: start;
}
.short-desc-overview > .project-contribution span{

    transform: translateY(100%);
    color: #F0B441 !important;
    transition: 600ms ease-out all;
}
.project-tile.active .short-desc-overview > .project-contribution span{
    transform: translateY(0%);
}
@media screen and (max-width: 1350px) {
    .page-two{
        display:flex;
       justify-content: space-between;
       gap:1.8vw;

    }
    .louis-canvas>div{
        transform: translateY(0) !important;
    }
    .louis-canvas canvas{
        transform: translateY(0) !important;
    }
 
 
    .floating-heads-element{
        align-self: end;

       
    }
    .angry-grid{
        height: 110% !important;
  
    }
    .angry-grid .grid-box-desc{
        font-size: 1.2em !important;
    }
    .threejs-wrap:has(.louis-canvas){
        position: absolute;
        top:4%;
    }
}
@media only screen and (max-width:800px){
    .project-contribution{
        display: none !important;
    }
    .site-title-container{
        width: 90vw;
        padding-inline: 5vw;
    }
    .page-two{
        margin-bottom: 30vh;
    }
    .site-title{
        font-size: 11.95vw !important;
    }
    .projects-list > .text-reveal-container > .main-page-p{
    
        color: #0f4bbe;
    }
    
   .get-in-touch.email-copied {
   font-size: 14vw !important;
   }
    .active .short-desc-overview>div {
        margin-top: 0px !important;
    }
    body:has(.project-details) .header-menu-toggler{
        min-width: 82px !important;

    }
    .header-page-id{
        display: none !important;
    }
    .project-tile *{
        pointer-events: all !important;
    }
    .box-index{
        top:4vw;
        right: 4vw !important;
    }
    
    .grid-box{
        padding: 4vw !important;

    }
    .angry-grid .grid-box-desc {
        font-size: 0.9em !important;
    }
    .grid-box-title{
        font-size: 2em !important;
    }
    .project-tile-3 .project-title {
        font-size: 7.72vw;
    }
    .project-title, .project-short-desc, .project-long-desc > div{
        color:var(--primary-color) !important;
    }
    .page-three{
        min-height: auto;
        height: fit-content;
    }
    .threejs-background-wrap{
        pointer-events: none;
    }
    .project-tile:not(:first-child) .project-title{
        padding-top: 1em;
    }
    .project-tile .project-short-desc {
        padding-bottom: 2em !important;
    }
    .angry-grid{
        transform: scale(1) !important;
    }
    .card{
        margin-inline:0 !important;
        width:90vw !important;
        padding-inline: 5vw;
        height: fit-content !important;
        padding-bottom: 10vh !important;
    }
    .cards-wrap{
        padding-top:0 !important;
    }
    .card-title-wrap{
        margin-block: 15vh !important;
    }
    .card-2{
        margin-bottom: 3vw !important;
    }
    .project-line{
        width: 100% !important;
    }
    .link-view-more{
        color: #0F4BBE !important;
    }
    .projects-list{
        mix-blend-mode: darken;
        background-color: var(--background);
    }
    .mobile-project-model{
        height:80vw;
        max-height: 70vh;
        overflow: hidden;
        display: flex;
        align-items: end;
      
    }
    .mobile-project-model > img{
        width: 100%;
        height: 101%;
        object-fit: contain;
        mix-blend-mode: darken;
    }
    .threejs-wrap:has(.project-canvas){
        display: none;
    }
    .project-long-desc, .project-short-desc{
        height: fit-content;
        padding-block: 20px !important;
        flex-direction: column;
    }
    .project-long-desc, .project-short-desc{
        font-size: 1.3em;
    }
    .project-long-desc{
        width:70% !important;

    }
    .project-short-desc{
        height:400px;
    }
    .project-long-desc > div:not(:first-child){
        padding-bottom: 1em;
    }
    .project-short-desc{
        width:30% !important;
    }
    .short-desc-tech{
        display: none;
    }
    .short-desc-links{
        width: 90vw !important;
    }
    .project-desc-wrap{
        display: flex;
        flex-direction: row-reverse;
        height: min-content;
    }
    .project-long-desc > div{
        width: 100%;
    }
    .active .short-desc-overview {
        transform: none !important;
    }
    .project-short-desc{

    }
    .active .project-long-desc, .active .project-short-desc{
        padding-bottom: 20px !important;
       
    }
    .cursor__ball, .cursor {
        display: none;
    }
    .active .project-long-desc{
        padding-top:50vh;
    }
    .page-two{
        flex-direction: column;
    }
    .floating-heads-element{
        display: none;
    } 
    .short-desc-overview{
        width: 80% !important;
    }
    .threejs-wrap:has(.louis-canvas){
        margin-top:40vh;
        width: 400%;
        max-height: 200vh;
        left: 18%;
        top:0;
        max-width: calc(850px*4);
    }
    section{
        width:90vw;
        padding-inline: 5vw;
    }
    .main-page-p{
        max-width: 90vw;
    }
    .main-page-title, .site-title{
        font-size: 11.9vw !important;
        padding-top: 0.2em;
    }
    .header-menu-toggler{
        transform: scale(0.6) !important;
    }
    .main-page-buttons-wrap{
        margin-bottom:2.7vw;
    }
    .projects-list{
        width: 100%;
        transform: scale(0.33);
    }
    .project-title{
        font-size: 8.3vw;
    }
    .footer-dialouge{
        flex-direction: column;
    }
    .footer-links{
        margin-top: 10vh;
        width: max-content;
    }
    .acknowledgement-p{
        max-width: 90vw;
    }
    .get-in-touch{
        margin-top:2.7vw;
        font-size: 14vw;
    }
    .project-desc-wrap *{
        font-size: 1.1em ;
        line-height: 1em;
    }
   
    .short-desc-links a{
        top:0;
    }
    .short-desc-links p {
        transform: translateY(-1em);
    }
    .project-tile{
        padding-block: 2em;
    }
    .project-line-bottom{
        display: none;
    }
    .project-title{
        text-align: center;
    }
    .link-view-more{
        width: 100% !important;
        text-align: center;
    }
    .text-reveal-container{
        clip-path: polygon(0px 0px, 100% 0%, 100% 100%, 0px 100%) !important;
        transform: none !important;
    }
    .page-five{
        z-index: 9998;
    }
    .header-toggled .header-menu-toggler{
        min-width: 100%;
    }
    body:has(.project-details) .header-toggled .header-menu-toggler{
        width: 328px !important;
        min-width: 100% !important; 
        transform: scale(1) !important;
    }
}
@media only screen and (max-width:550px){
    .main-page-buttons-wrap{
        flex-direction: column;
        gap: 1em;
        margin-bottom:2.7vw;
    }
   
    .main-page-buttons-wrap a{
        width: max-content;

    }
    .threejs-wrap:has(.louis-canvas){
        margin-top:30vh;
    
    }
    .floating-heads-element{
        display: none;
    }
    body .card .angry-grid{
        min-height: 140vh !important;
    }

}
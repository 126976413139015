@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;800&display=swap');

/* define component style guide here */
:root {
  --primary-color: #202A45;
  --secondary-color: #ffffff;
  --secondary: #6F7686;
  --third: #D0D4EB;
  --main-invert: #DFD5BA;
  --third-invert:#2F2B14;
  --background-color:#E4E8EB;
  
  --font-family: 'Inter', sans-serif;

  --font-size-heading-link: 6.95vw;
  --font-size-heading-mobile: 56px;
  --font-size-base: 16px;
  --font-size-heading-mobile: 56px;
}
*{
  font-family: var(--font-family);
}
.grid-overlay{
  width: 100vw;
  height:100vh;
  position: fixed;
  background-image: url('/public/grid.png');
  z-index: 9999;
  background-size:100%;
  top:0;
  pointer-events: none;
}
@media only screen and (max-width:800px){
  .grid-overlay{
    z-index: 9999;
    background-image: url(/public/grid-Mobile.png);   
    transform: scale(1.05);
  }
}

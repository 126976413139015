
body{
    margin:0;

    max-width: 100vw;
    overflow-x: hidden;
    background-color: var(--background-color);
}

.App {
 

    width:100vw;


    overflow-x: hidden;

    position: relative;
  
  
  }

  body:has( .safari-user) header{
      display: none !important; 
  }

  section{
    overflow-x: hidden;
    width:94.6vw;
    padding-inline: 2.7vw;
    height: 100vh;
    background-color:var(--background-color) !important;
    z-index: 1;
  
  }
  .more-active{
    background-color:var(--background-color) !important;
    min-height: 100vh;
  }
  .more-active .project-canvas{
    position: relative;
  }
  .overflow{
    overflow: hidden;
  }
  .project-canvas {
 
  
    position: fixed;
    top: 0;
    /* filter: blur(5px); */
    /* transform: scale(3); */
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    pointer-events: none;
    opacity: 1;
    /* transform: translateY(100vh); */

  }
  .more-active .project-canvas canvas{
    transform:  translateY(0vh) !important;
  }
  .more-active .threejs-background{
    transform:  translateY(0vh) !important;
    height: 100% !important;
    transform: scale(0.713) !important;
  }
  .project-canvas{
    transform: translateX(-75vw) !important;
  }
  .threejs-wrap:has(.louis-canvas){
    z-index: 20;
  }
  .louis-canvas canvas{
    transform: scale(1.15);

  }
  .title{
    text-transform: uppercase;
    margin:0;
    color:var(--primary-color)
  }
  .portfolio-page-container{
    background-color: var(--background-color);
  }
 

.cursor {
  pointer-events: none;

     
} 

.cursor__ball, .cursor {
  position: fixed;
  top: 0;
  left: 0;
  mix-blend-mode:difference;
  z-index: 9999;
  overflow: visible;
  backdrop-filter: blur(100px);
  clip-path:url('/public/AppItems/Subtract.svg');
}  
.cursor__ball svg, .cursor svg{
  overflow: visible;
}
.cursor__ball circle, .cursor circle {
  fill: #f0b441;
  transform-origin: center center;
  transition: 700ms cubic-bezier(0.76, 0, 0.24, 1);
  

}

.App:not(.scrolled) .loading-numbers{
  display: flex !important;
}
body:has(a:hover) circle, body:has(.hoverable:not(.active):hover) circle{
  scale: 3;

}

.cursor__ball{

}
.mousedown .cursor__ball circle, .mousedown .cursor circle{
  scale: 10;
}


.page-one, .page-two, .page-three, .page-four, .card, .more-active-project-page{
  max-width: calc(2100px ) !important;
  left: 0;
  
  margin: 0px auto;
}
.page::-webkit-scrollbar {
  display: none;
}
.page {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
@media only screen and (min-width:2213.4px){
  .main-page-title, .site-title{
    font-size: 275px !important;
    margin: 0px auto;
  }
  .site-title{
    margin-right: 0.11em !important;
  }
  .get-in-touch.title{
    font-size: 325px !important;
    margin: 0px auto;
    letter-spacing: -0.06em !important;
  }
  .threejs-wrap:has(.louis-canvas) {
    max-width: calc(2213.4px*1.6);
  }
  .threejs-wrap {
    max-width: 2100px;
    max-height: calc(2100px *2);
    display: flex;
    justify-content: center;

 
  }
  .threejs-wrap:has(.project-canvas) .project-canvas{
    max-width: calc(1791px * 2) !important;
    height: 1791px !important;

        transform: translateX(-25vw) !important;

  }
  .projects-list {
    width: 52.9% !important;
  }
  .project-title{
    font-size: 107px !important;
  }
  .project-tile-3 .project-title {
    font-size: 99px !important;
  }
  .first-page-wrap .floating-heads-element{
    margin-right: 0px !important;
  }
  .floating-head.head-1{
    transform: scaleX(-0.9) scaleY(0.9) translateX(0) !important;
  }
  .floating-head.head-2{
    transform: rotate(40deg) scale(0.5) translateX(0) !important;
  }
  .floating-head.head-3{
    transform: rotate(-50deg) scale(0.7) translateX(0) !important;
  }
  .floating-head.head-4{
    transform: rotate(-170deg) scale(0.4) translateX(0) !important;
    }
  .floating-head.head-5{
    transform: rotate(-170deg) scale(1) translateX(0) !important;
  }
  .floating-head.head-6{
    transform: rotate(110deg) scale(1.2) translateX(0) !important;
  }
  .floating-head.head-7{
    transform: rotate(-240deg) scale(0.35) translateX(0) !important;
  }
  .floating-head.head-8{
    transform: rotate(70deg) scale(0.6) translateX(0) !important;
  }
  .cards-wrap, .cards-wrap > .pin-spacer{
    display: flex !important;
    flex-direction: column;
    align-items: center;
  }
  .card-title-wrap .title {
  font-size: 190px !important;
 letter-spacing: -0.07em;

}
.threejs-background-wrap{
  max-width: 2100px;
}
.more-active-project-name {
  width: calc(2213.4px - 2.7vw) !important;
  padding: 0 !important;
}
.more-active-project-name > .text-reveal-container> h1, .project-less-details .title {
  font-size: 200px !important;

}
.project-container{
  display: flex;
  justify-content: center;
}
.project-details{
  max-width: 2100px;
}
.project-details .dual-image-section img, .project-details .dual-image-section video {
  width: calc(2100px / 2.1);
  height: calc(2100px/2.1);

}
.project-details .large-image-section{
  width: calc(2100px);
}
.project-details .dual-image-section, .project-details .dual-desc-section {
width: calc(2100px);
}
body:has(.project-details){
  background-color: white !important;
}
}
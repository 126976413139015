.project-details{
    width: calc(100vw - (2.7vw * 2));
    padding-inline: 2.7vw;
    background-color: white;
    border-radius: 0px 0px 20px 20px;
    transform-origin: bottom center;
}
.project-container{
    margin-bottom: calc(2.7vw * 3);
}
.project-details h1{
    margin:0;
}
.project-details img{
    object-fit: cover;
    height: 100%;
    width: 100%;
    border-radius: 5px;
}
.large-image-section{
    aspect-ratio: 16/9;
    width: calc(100vw - (2.7vw * 2));

    overflow: hidden;
    margin-bottom: 2.7vw;
    opacity: 0;
transform: translateY(10px) scale(0.95);
transform-origin: bottom center;
}
.dual-image-section{
    width: calc(100vw - (2.7vw * 2));

    overflow: hidden;
    padding-bottom: 2.7vw;
    display: flex;
    justify-content: space-between;
    opacity: 0;
    transform: translateY(10px) scale(0.99);
    transform-origin: bottom center;
}
.dual-image-section img, .project-details video{
    width: calc(50vw - (2.7vw*1.5));
    height: calc(50vw - 4.05vw);
    border-radius: 5px;
    background-color: black;
}
.dual-image-section img#contain{
    object-fit: contain;
}
.website-link{
    position: relative;
    font-weight: 500;
    font-size: 2em;
    cursor: pointer;
    padding-right: 1.1em;
    color: var(--primary-color);
}

.dual-desc-section{
    width: calc(100vw - (2.7vw * 2));
    overflow: hidden;
    padding-bottom: 2.7vw;
    display: flex;
    justify-content: space-between;
    opacity: 0;
transform: translateY(10px) scale(0.99);
transform-origin: bottom center;
}
.dual-desc-section > div{
    width: calc(50% - (2.7vw/2));
    margin-block: 2.7vw;
}
.dual-desc-section > div p{
   font-weight: 500;
   font-size: 2em;
   color: var(--primary-color);
   margin: 0;
 
}
.project-less-details{
    padding-block: 2.7vw;
}
.project-less-details p{
    font-weight: 500;
    font-size: 2em;
    color: var(--primary-color);
    margin: 0;
}
.project-less-details h1{
font-size: 9vw;
letter-spacing: -0.05em;
    color: var(--primary-color);
}
.dual-less-section .text-reveal-element{
    padding:0 !important
}
body:has(.project-details) .header-menu-toggler{

    transform: scale(0.8) !important;
    min-width: 211px !important;
}
body:has(.project-details){
    background-color: var(--background-color);
}
body:has(.project-details) .header-page-id > .project-home{
    transform:translateY(-100%);
}
body:has(.project-details) .header-page-id > p{
    transition: 0s all !important;
    transform: translateY(1em) !important;
}
.dual-less-section{
    display: flex;
    justify-content: space-between;
    margin-bottom: 2.7vw;
}
.announcement{
    font-weight: 500;
    font-size: 2em;
    color: #0F4BBE !important;
}
.tech-used{
    opacity: 0.7;
}
.dual-less-section > div{
    width: 49%;
    margin:0;
  
}
div:has(>.tech-used){
    padding-bottom: 2.7vw !important;
}
.tech-used{
    margin:0 !important;
    font-weight: 500;
    font-size: 1.2em;
    color: var(--primary-color);
    margin: 0;
}
.project-less-details .title{
    position: absolute;
    display: flex;
    align-items: center;
    transform: translateY(-2.7vh);
    z-index: 10;
    line-height: 9vw;
}
.title-wrap{
    height: 9vw;
    padding-bottom:2.7vw;
}
.rendered-section-wrap{
    opacity: 0;
}
.project-details-amp .title{
    font-size: 8.3vw;
    line-height: 8.3vw;
}
.youtube-video-section iframe{
    width: 100%;
    aspect-ratio: 16 / 9;
    height: 100%;
    border-radius: 10px;
}
.extra-details-flex{
    display: flex;
    justify-content: space-between;
}
.extra-details-flex > div{
    width: 49.5%;
}
@media only screen and (max-width:800px){
    
    .project-details-amp .title{
        font-size: 8vw;
        line-height: 8vw;
    }
    .extra-details-flex{
        flex-direction: column;
    }
    .extra-details-flex > div{
        width: 100%;
        padding-bottom: 2em;
    }
    .website-link{
        margin-bottom: 5vw;
    }
    .project-less-details > .text-reveal-container{
        margin-bottom: 5vw;
    }
    .title-wrap .title{
        transform: none !important;
        height: auto !important;
    }
    .project-details{
        width: 90vw !important;
        padding-inline: 5vw;
    }
    .dual-less-section, .dual-desc-section{
        flex-direction: column;
        gap: 1em;
        padding-bottom: 1em;
    }
    .project-less-details h1 {
    font-size: 8.6vw;
    }
    .dual-desc-section{
        margin-block: 2em;
    }
    .dual-desc-section > div{
        width: 90vw;
        
    }
    .large-image-section{
        width: 90vw;
    }
    .dual-image-section{
        flex-direction: column;
        gap: 2.7vw;
    }
    .dual-image-section img, .project-details video {
        width: 90vw;
        height: 90vw;
    }
    
    .dual-less-section>div{
        width: 100%;
    }
}